import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VImg } from 'vuetify/lib/components/VImg';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import Resize from 'vuetify/lib/directives/resize';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{directives:[{def: Resize,name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"item-key":"itemId","fixed-header":"","disable-sort":"","height":_vm.tableHeight,"headers":_vm.tableHeaders,"items":_vm.account.quotes,"loading":_vm.loading,"server-items-length":_vm.account.quotes.length,"options":_vm.tableOptions},on:{"update:options":[function($event){_vm.tableOptions=$event},_vm.fetchWebUserQuotes]},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":""}},[_c(VToolbarTitle,[_vm._v("My Quotes")])],1)]},proxy:true},{key:"item.imageThumbUrl",fn:function(ref){
var item = ref.item;
return [_c(VImg,{staticClass:"my-2",attrs:{"src":item.imageThumbUrl,"width":50,"height":50,"position":"center center","contain":""}})]}},{key:"item.webUserPrice",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toNumber")(item.webUserPrice)))])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }