<template>
	<v-data-table
		v-resize="onResize"
		item-key="itemId"
		fixed-header
		disable-sort
		:height="tableHeight"
		:headers="tableHeaders"
		:items="account.quotes"
		:loading="loading"
		:server-items-length="account.quotes.length"
		:options.sync="tableOptions"
		v-on:update:options="fetchWebUserQuotes"
	>
		<template v-slot:top>
			<v-toolbar flat>
				<v-toolbar-title>My Quotes</v-toolbar-title>
			</v-toolbar>
		</template>
		<template v-slot:[`item.imageThumbUrl`]="{ item }">
			<v-img
				class="my-2"
				:src="item.imageThumbUrl"
				:width="50"
				:height="50"
				position="center center"
				contain
			/>
		</template>
		<template v-slot:[`item.webUserPrice`]="{ item }">
			<span>{{ item.webUserPrice | toNumber }}</span>
		</template>
	</v-data-table>
</template>

<script>
	import { mapState } from "vuex";
	export default {
		name: "MyQuotes",
		computed: {
			...mapState([
				"account",
			]) /* 'account' is referring to the module account.js */,
		},
		created() {
			this.onResize;
		},
		data() {
			return {
				loading: false,
				tableHeight: null,
				tableOptions: {
					page: 1,
					itemsPerPage: 10,
				},
				tableHeaders: [
					{ text: "imageThumbUrl", value: "imageThumbUrl" },
					{ text: "itemId", value: "itemId" },
					{ text: "itemName", value: "itemName" },
					{ text: "webUserPrice", value: "webUserPrice", align: "end" },
					{ text: "currency", value: "currency" },
					{
						text: "minimalOrderQty",
						value: "minimalOrderQty",
						align: "end",
					},
				],
			};
		},
		methods: {
			fetchWebUserQuotes() {
				let params = {};
				params.pageNr = this.tableOptions.page;
				params.pageRows = this.tableOptions.itemsPerPage;

				this.loading = true;
				this.$store
					.dispatch("account/fetchWebUserQuotes", params)
					.then(() => {
						this.loading = false;
					});
			},
			onResize() {
				this.cardHeight =
					window.innerHeight -
					this.$vuetify.application.top -
					this.$vuetify.application.footer -
					2;
				this.tableHeight = this.cardHeight;
				this.tableHeight -= 72; // card-title
				this.tableHeight -= 60; // pagination
				if (this.$vuetify.breakpoint.mdAndDown) {
					this.tableHeight -= 56;
				}
			},
		},
	};
</script>
